'use client'
import { type FC, useState } from 'react'
import { useParams, useRouter } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useSession, useTranslator, useModal } from '@/hooks'
import { autoComplete, inputPattern, cookie } from '@/utils'
import { Button } from '..'
import { Input } from '../inputs'
import { mq, vw, font12_12, font15_15 } from '@/styles'
import { SecondaryNav } from 'src/components/layout/SecondaryNav'
import { MODALS } from '@/context'
import { GraphQlErrors } from './GraphQlErrors'
import { DisplayError } from './DisplayError'

type TypeFormInputs = {
  email: string,
  password: string
}

function getExpiryDate(): string {
  const date = new Date()
  date.setHours(date.getHours() + 6)
  return date.toISOString()
}

export const FormAuthLogin: FC = () => {
  const { register, handleSubmit, formState: { errors: formErrors }, watch } = useForm<TypeFormInputs>()
  const { lang } = useParams()
  const { t } = useTranslator()
  const { push } = useRouter()
  const { setLoaderActive } = useSession()
  const { updatePayload } = useModal(MODALS.AUTH)
  const [apiErrors, setApiErrors ] = useState(null)
  const watchEmail = watch('email')
  const watchPassword = watch('password')

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData) => {
    setLoaderActive(true)

    try {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formData, lang })
      })

      if (!res.ok) {
        throw new Error('FormAuthLogin submit failed')
      }

      if (res.status === 200) {
        const data = await res.json()
        if (Array.isArray(data.errors) && data.errors.length > 0) {
          setLoaderActive(false)
          console.warn('Login error', data.errors[0]?.message)
          console.log('Login error', data.errors[0].extensions?.code)
          setApiErrors( data.errors)
          updatePayload({ payload: formData.email, open:false })
          return
        }

        if (data?.data?.userLogin && data.data.userLogin?.ok) {
          setApiErrors(null)
          const { token, expiryDate } = data.data.userLogin
          const expiryDateUpdated = typeof expiryDate === 'string' ? expiryDate : getExpiryDate()
          cookie.set('DY_AICAM_USER', token, { maxAge: getMaxAge(expiryDateUpdated) })
          if (data?.data?.userLogin.user.credits > 0) {
            push(`/${lang}/projects?login=true`)
          } else {
            push(`/${lang}/credits`)
          }
        }

      }
    } catch (e) {
      setLoaderActive(false)
      setApiErrors(e)
      console.warn('Login submit - Catch e', e)
    }

  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <legend><h1>{t('auth.login.title')}</h1></legend>
      <SecondaryNav/>
      <ErrorsWrapper>
        {apiErrors && <GraphQlErrors errors={apiErrors} />}
        {formErrors && <DisplayError errors={formErrors} />}
      </ErrorsWrapper>
      <Input type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required={{
        required: t('form.errors.pattern_email') as string,
        pattern: inputPattern.email
      }} error={formErrors.email} />
      <Input type='password' name='password' register={register} label={t('form.fields.password') as string} placeholder='password' autoComplete={autoComplete.password.current} icon={'password'} required={{
        required: t('form.errors.pattern_password') as string,
        pattern: inputPattern.password
      }} error={formErrors.password}/>
      <ButtonReset type='button' onClick={() => { push(`/${lang}/forgot-password`) }}>{t('auth.forgot_password.title')}</ButtonReset>
      <Button disabled={(Object.keys(formErrors).length > 0) || !watchEmail || !watchPassword } type='submit' text={t('commons.actions.login') as string} />
    </Form>
  )
}

const Form = styled.form`
  width: 90%;
`
const ErrorsWrapper = styled.ul`
  margin: 0 auto ${vw(20, 'mobile')};
  padding: 0 ${ vw(4.25, 'mobile') };
  width: 95%;

  ${mq.greaterThan('nexus7')} {
    margin: 0 auto ${vw(20, 'tablet')};
    padding: 0 ${ vw(4.25, 'tablet') };
  }

  ${mq.greaterThan('tablet') } {
    margin: 0 auto ${vw(30, 'desktop')};
    padding: 0 ${vw(12.5, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: 0 auto 30px;
    padding: 0 12.5px;
  }
`

const ButtonReset = styled.button`
  ${font12_12(true, 400)}
  background-color: transparent;
  color: var(--color-white);
  display: block;
  letter-spacing: 0px;
  margin-left: auto;
  opacity: .7;
  padding: ${vw(10, 'mobile')} ${vw(24.25, 'mobile')} 0;
  text-decoration: none;
  transform: translateY(-${vw(20, 'mobile')});
  transition: 200ms opacity ease-out;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(10, 'tablet')} ${vw(24.25, 'tablet')} 0;
    transform: translateY(-${vw(20, 'tablet')});
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(10, 'desktop')} ${vw(24.25, 'desktop')} 0;
    transform: translateY(-${vw(20, 'desktop')});
  }

  ${mq.greaterThan('tablet')} {
    ${font15_15(true, 400)}
  }

  ${mq.greaterThan('desktop-xs')} {
    ${font12_12(false, 400)}
  }

  ${mq.greaterThan('desktop')} {
    padding: 10px 24.24px 0;
    transform: translateY(-20px);
  }

  &:hover {
    opacity: 1;
  }
`

const getMaxAge = (dateString: string): number => {
  // Parse the date string into a Date object
  const date = new Date(dateString)
  // Calculate the time difference between the parsed date and the current date
  const currentTime = new Date()
  const timeDifferenceMilliseconds = date.getTime() - currentTime.getTime()
  // Convert the time difference to seconds for maxAge
  const maxAgeSeconds = Math.floor(timeDifferenceMilliseconds / 1000)

  return maxAgeSeconds
}
